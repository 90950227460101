import {
  BRANCH_DATA_REQUEST,
  SALE_DATA_REQUEST,
  UPDATE_PAGINATION_KEYVERIFICATION_LIST,
  UPDATE_KEYVERIFICATION_LIST,
  UPDATE_KEYVERIFICATION_DETAIL_LIST,
  UPDATE_KEYVERIFICATION_LOADING,
  UPDATE_KEYVERIFICATION_LIST_REQUEST,
  UPDATE_PAGINATION_KEYVERIFICATION_DETAIL_LIST
} from "../actions/types"

const initialState = {
  keyverificationDetailList: [],
  saleList: [],
  keyverificationList: [],
  totalData: 0,
  pagination: {
    currentPage: 1,
    perPage: 10
  },
  loading: false,
  keyverificationRequest: {
    status: "",
    startDate: "",
    endDate: ""
  },
  detailPagination: {
    currentPage: 1,
    perPage: 10
  }
}

const keyverification = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_KEYVERIFICATION_LIST_REQUEST:
      return {
        ...state,
        keyverificationRequest: {
          ...state.keyverificationRequest,
          [payload.name]: payload.value
        }

      }
    case UPDATE_KEYVERIFICATION_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_KEYVERIFICATION_LIST:
      return {
        ...state,
        keyverificationList: payload.data,
        totalData: payload.totalData
      }
    case UPDATE_PAGINATION_KEYVERIFICATION_DETAIL_LIST:
      return {
        ...state,
        detailPagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_PAGINATION_KEYVERIFICATION_LIST:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_KEYVERIFICATION_DETAIL_LIST:
      return {
        ...state,
        keyverificationDetailList: payload.data,
        totalData: payload.totalData
      }
    case SALE_DATA_REQUEST:
      return {
        ...state,
        saleList: payload
      }
    default:
      return state
  }
}

export default keyverification