import {
  SUBMIT_MEMBER_SUCCESS,
  UPDATE_MEMBER_LIST_LOADING,
  UPDATE_MEMBER_LIST_PAGINATION,
  UPDATE_MEMBER_FORM_BY_ID,
  UPDATE_GROUP_DATAS,
  UPDATE_MEMBER_GROUP_LIST_FILTER
} from "../actions/type"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  filter: ""
}

const memberGroup = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_GROUP_DATAS:
      return {
        ...state,
        data: payload
      }
    case UPDATE_MEMBER_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_SUCCESS:
      return {
        ...state,
        data: payload.data,
        totalData: payload.totalData
      }
    case UPDATE_MEMBER_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_MEMBER_FORM_BY_ID:
      return {
        ...state,
        surveyFromData: payload.response
      }
    case UPDATE_MEMBER_GROUP_LIST_FILTER:
      return {
        ...state,
        filter: payload
      }
    default:
      return state
  }
}

export default memberGroup