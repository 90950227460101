import { 
  UPDATE_COUPON_CREATE_FORM,
  SUBMIT_COUPON_CREATE_FORM_REQUEST_SUCCESS,
  SUBMIT_COUPON_CREATE_FORM_LOADING,
  UPDATE_TEMPLATE_PAGE_COUPON_CREATE_FORM
} from "../actions/types"

const initialState = { 
  loading: false,
  requestSaveData: {
    hidden: ''
  },
  page: null
}

const couponCreateFormReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_COUPON_CREATE_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    case SUBMIT_COUPON_CREATE_FORM_REQUEST_SUCCESS: {
      return initialState
    }
    case SUBMIT_COUPON_CREATE_FORM_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_TEMPLATE_PAGE_COUPON_CREATE_FORM: 
      return {
        ...state,
        page: payload
      }
    default:
      return state
  }
}

export default couponCreateFormReducer