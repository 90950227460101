import {
  RECIPESTS_DATA_REQUEST,
  DASHBOARD_LOADING,
  UPDATE_PAGINATION_COUPON_LIST,
  DASHBOARD_DATA,
  DASHBOARD_CAMPAIGN
} from "../actions/types"

const initialState = {
  recipestsList: [],
  reportList: [],
  totalData: 0,
  pagination: {
    currentPage: 1,
    perPage: 10
  },
  loading: false,
  dashboardData: [],
  dashboardCampign: ""
}

const couponDetail = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case DASHBOARD_CAMPAIGN:
      return {
        ...state,
        dashboardCampign: payload
      }
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: payload
      }
    case DASHBOARD_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_PAGINATION_COUPON_LIST:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case RECIPESTS_DATA_REQUEST:
      return {
        ...state,
        recipestsList: payload.data,
        totalData: payload.total
      }
    default:
      return state
  }
}

export default couponDetail
