export const dropdownList = [
  { value: 1, label: "test1" },
  { value: 2, label: "test2" },
  { value: 3, label: "test3" },
  { value: 4, label: "test4" }
]

export const uploadFileResponse = {
  headerData:[
    "__EMPTY",
    "__EMPTY1",
    "__EMPTY2",
    "__EMPTY3",
    "__EMPTY4",
    "__EMPTY5",
    "__EMPTY6",
    "__EMPTY7",
    "__EMPTY8"
  ],
  bodyData: [
    [
      ['John1', 'John2', 'John3'], 
      ['30', '30', '30'], 
      ['Los Angeles', 'Los Angeles', 'Los Angeles'], 
      ['Female', 'Female', 'Female'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3']
    ],
    [
      ['John1', 'John2', 'John3'], 
      ['30', '30', '30'], 
      ['Los Angeles', 'Los Angeles', 'Los Angeles'], 
      ['Female', 'Female', 'Female'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3'], 
      ['John1', 'John2', 'John3']
    ]
  ]
}