export const SUBMIT_MEMBER_USER_POINT_SUCCESS = "SUBMIT_MEMBER_USER_POINT_SUCCESS"
export const UPDATE_MEMBER_USER_POINT_LIST_LOADING = "UPDATE_MEMBER_USER_POINT_LIST_LOADING"
export const UPDATE_MEMBER_USER_POINT_LIST_PAGINATION = "UPDATE_MEMBER_USER_POINT_LIST_PAGINATION"
export const SUBMIT_MEMBER_USER_REWARDS_SUCCESS = "SUBMIT_MEMBER_USER_REWARDS_SUCCESS"
export const UPDATE_MEMBER_USER_REWARDS_LIST_LOADING = "UPDATE_MEMBER_USER_REWARDS_LIST_LOADING"
export const UPDATE_MEMBER_USER_REWARDS_LIST_PAGINATION = "UPDATE_MEMBER_USER_REWARDS_LIST_PAGINATION"
export const SUBMIT_MEMBER_USER_USED_SUCCESS = "SUBMIT_MEMBER_USER_USED_SUCCESS"
export const UPDATE_MEMBER_USER_USED_LIST_LOADING = "UPDATE_MEMBER_USER_USED_LIST_LOADING"
export const UPDATE_MEMBER_USER_USED_LIST_PAGINATION = "UPDATE_MEMBER_USER_USED_LIST_PAGINATION"
export const SUBMIT_MEMBER_USER_EXPIRE_SUCCESS = "SUBMIT_MEMBER_USER_EXPIRE_SUCCESS"
export const UPDATE_MEMBER_USER_EXPIRE_LIST_LOADING = "UPDATE_MEMBER_USER_EXPIRE_LIST_LOADING"
export const UPDATE_MEMBER_USER_EXPIRE_LIST_PAGINATION = "UPDATE_MEMBER_USER_EXPIRE_LIST_PAGINATION"
export const UPDATE_MEMBER_USER_FILTER = "UPDATE_MEMBER_USER_FILTER"