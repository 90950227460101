import { 
  SUBMIT_CAMPAIGN_FORM_LOADING,
  UPDATE_CAMPAIGN_FORM,
  CLEAR_CAMPAIGN_FORM,
  REQUEST_DROPDOWN_LIST
} from "../actions/types"

const initialState = {
  loading: false,
  requestSaveData: {
    name: "",
    description: "",
    startDate: new Date(),
    timeStart: new Date(),
    endDate: new Date(),
    timeEnd: new Date(),
    phoneNumber: false,
    keyVerifi: false,
    dropdown: [],
    coupons: []
  },
  dropdownList: []
}

const campaignFormReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_CAMPAIGN_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    case SUBMIT_CAMPAIGN_FORM_LOADING:
      return {
        ...state,
        loading: payload
      }
    case CLEAR_CAMPAIGN_FORM: 
      return initialState
    case REQUEST_DROPDOWN_LIST:
      return {
        ...state,
        dropdownList: payload
      }
    default:
      return state
  }
}

export default campaignFormReducer