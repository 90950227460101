import { 
  UPDATE_LOADING_MEMBER_CREATE,
  ADD_NEW_QUESTION_MEMBER_CREATE,
  CLEAR_CREATE_QUESTION_REQUEST_MEMBER,
  GET_MEMBER_DATA,
  UPDATE_TAB_SETTING_MEMBER_CREATE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_CREATE_CODE
} from "../actions/types"
import { defaultTemplate } from "../../../domain/member.mock"

const initialState = {
  code: 0,
  loading: false,
  page: defaultTemplate,
  tabSetting: {
    tabIndex: 0,
    previewMode: "monitor"
  },
  memberName: "",
  memberCode: "",
  memberStatus: "",
  memberRequest: {
    logoImg: "",
    backgroundImg: "",
    timeLimitSurvey: "",
    timeLimitOnePage: "",
    backgroundColor: "#FFFFFF",
    displayLogoImg: "",
    displayBackgroundImg: ""
  }
}

const memberCreate = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_LOADING_MEMBER_CREATE:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        memberRequest: {
          ...state.memberRequest,
          [payload.name]: payload.value
        }
      }
    case GET_MEMBER_DATA:
      return {
        ...state,
        memberName: payload.memberName,
        page: payload.question,
        memberCode: payload.memberCode,
        memberStatus: payload.memberStatus,
        memberRequest: {
          ...state.surveyRequest,
          timeLimitOnePage: payload.timeLimitOnePage,
          timeLimitSurvey: payload.timeLimitSurvey,
          backgroundColor: payload.backgroundColor,
          logoImg: payload.logoImg,
          backgroundImg: payload.backgroundImg
        }
      }
    case ADD_NEW_QUESTION_MEMBER_CREATE:
      return {
        ...state,
        page: payload
      }
    case CLEAR_CREATE_QUESTION_REQUEST_MEMBER:
      return {
        ...state,
        code: initialState.code,
        page: initialState.page,
        tabSetting: initialState.tabSetting,
        memberRequest: initialState.memberRequest
      }
    case UPDATE_TAB_SETTING_MEMBER_CREATE:
      return {
        ...state,
        tabSetting: payload
      }
    case UPDATE_MEMBER_CREATE_CODE:
      return {
        ...state,
        code: payload
      }
    default:
      return state
  }
}

export default memberCreate