import {
  GET_DASHBOARD_DATA,
  UPDATE_LOADING_DASHBOARD,
  UPDATE_PAGINATION_DASHBOARD_SURVEY_LIST
} from "../actions/types"

const initialState = {
  loading: false,
  createdDate: "",
  getLink: 0,
  getLinkPercen: "",
  id: 0,
  response: 0,
  responseMonth: [],
  responsePercen: "",
  status: false,
  surveyName: "",
  dashboardList: [],
  linkCode: "",
  totalList: 0,
  pagination: {
    currentPage: 1,
    perPage: 10
  }
}

const dashboardSurvey = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        createdDate: payload.createdDate,
        getLink: payload.getLink,
        getLinkPercen: payload.getLinkPercen,
        id: payload.id,
        response: payload.response,
        responseMonth: payload.responseMonth,
        responsePercen: payload.responsePercen,
        status: payload.status,
        surveyName: payload.surveyName,
        dashboardList: payload.dashboardList,
        linkCode: payload.linkCode,
        totalList: payload.totalList
      }
    case UPDATE_LOADING_DASHBOARD:
      return {
        ...state,
        loading: payload
      }
    default:
      return state
    case UPDATE_PAGINATION_DASHBOARD_SURVEY_LIST:
      return {
        ...state,
        pagination:{
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
  }
}

export default dashboardSurvey