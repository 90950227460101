import {
  ADD_NEW_QUESTION,
  CLEAR_CREATE_QUESTION_REQUEST,
  UPDATE_TAB_SETTING_SURVEY,
  GET_SURVEY_CODE,
  GET_SURVEY_DATA,
  UPDATE_SURVEY_REQUEST,
  UPDATE_LOADING_QUESTION
} from "../actions/types"

const initialState = {
  page: [],
  tabSetting: {
    tabIndex: 0,
    previewMode: "monitor"
  },
  surveyName: "",
  linkCode: "",
  surveyCode: "",
  surveyStatus: "",
  surveyRequest: {
    logoImg: "",
    backgroundImg: "",
    timeLimitSurvey: "",
    timeLimitOnePage: "",
    backgroundColor: "#FFFFFF",
    displayLogoImg: "",
    displayBackgroundImg: ""
  },
  loading: false
}

const createSurvey = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_LOADING_QUESTION:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_SURVEY_REQUEST:
      return {
        ...state,
        surveyRequest: {
          ...state.surveyRequest,
          [payload.name]: payload.value
        }
      }
    case GET_SURVEY_CODE:
      return {
        ...state,
        linkCode: payload
      }
    case GET_SURVEY_DATA:
      return {
        ...state,
        surveyName: payload.surveyName,
        page: payload.question,
        surveyCode: payload.surveyCode,
        linkCode: payload.linkCode,
        surveyStatus: payload.surveyStatus,
        surveyRequest: {
          ...state.surveyRequest,
          timeLimitOnePage: payload.timeLimitOnePage,
          timeLimitSurvey: payload.timeLimitSurvey,
          backgroundColor: payload.backgroundColor,
          logoImg: payload.logoImg,
          backgroundImg: payload.backgroundImg
        }
      }
    case ADD_NEW_QUESTION:
      return {
        ...state,
        page: payload
      }
    case CLEAR_CREATE_QUESTION_REQUEST:
      return {
        ...state,
        page: [],
        linkCode: initialState.linkCode,
        tabSetting: initialState.tabSetting,
        surveyRequest: initialState.surveyRequest
      }
    case UPDATE_TAB_SETTING_SURVEY:
      return {
        ...state,
        tabSetting: payload
      }
    default:
      return state
  }
}

export default createSurvey