import {
  UPDATE_SELECT_ROW_PEOPLE,
  UPDATE_COLUMN_FILE_PEOPLE,
  UPDATE_LOADING_CREATE_PEOPLE,
  UPDATE_STEP_IMPORT_FILE,
  UPDATE_SELECT_TAG_CREATE,
  UPDATE_SELECT_EDIT_PEOPLE_PROFILE,
  UPDATE_MODE_PEOPLE_PROFILE,
  UPDATE_FILE_PEOPLE_IMPORT,
  UPDATE_IMPORT_DATA,
  UPDATE_STATUS_FETCH
} from "../actions/types"

export const defaultImportData = {
  fileList: [],
  fileloading: false,
  file_size: 0,
  filemax: 1,
  errorMessage: ''
}

const initialState = {
  loading: false,
  loadingCreate: false,
  selectRowsPeople: [],
  columnPeople: [],
  importStep: 1,
  selectTagCreate: [],
  selectEditPeopleProfile: '',
  mode: 'view',
  filePeopleImport: null,
  importData: defaultImportData,
  statusFetch: false
}

const PeopleReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_LOADING_CREATE_PEOPLE:
      return {
        ...state,
        loadingCreate: payload
      }
    case UPDATE_SELECT_ROW_PEOPLE:
      return {
        ...state,
        selectRowsPeople: payload
      }
    case UPDATE_COLUMN_FILE_PEOPLE:
      return {
        ...state,
        columnPeople: payload
      }
    case UPDATE_STEP_IMPORT_FILE:
      return {
        ...state,
        importStep: payload
      }
    case UPDATE_SELECT_TAG_CREATE:
      return {
        ...state,
        selectTagCreate: payload
      }
    case UPDATE_SELECT_EDIT_PEOPLE_PROFILE: 
      return {
        ...state,
        selectEditPeopleProfile: payload
      }
    case UPDATE_MODE_PEOPLE_PROFILE:
      return {
        ...state,
        mode: payload
      }
    case UPDATE_FILE_PEOPLE_IMPORT:
      return {
        ...state,
        filePeopleImport: payload
      }
    case UPDATE_IMPORT_DATA:
      return {
        ...state,
        importData: {
          ...state.importData,
          fileList: payload.fileList,
          to: payload.to,
          file_size: payload.file_size
        }
      }
    case UPDATE_STATUS_FETCH:
      return {
        ...state,
        statusFetch: payload
      }
    default:
      return state
  }
}

export default PeopleReducer