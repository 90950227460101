import {
  UPDATE_PAGINATION,
  UPDATE_SELECT_ROW_TAG,
  UPDATE_TAG_LIST,
  UPDATE_TAG_LOADING
} from "../actions/types"

const initialState = {
  loading: false,
  selectRowsTag: [],
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  data: [],
  total: 0
}

const TagReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_TAG_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_SELECT_ROW_TAG:
      return {
        ...state,
        selectRowsTag: payload
      }
    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...payload
        }
      }
    case UPDATE_TAG_LIST:
      return {
        ...state,
        data: payload.data,
        total: payload.total
      }
    default:
      return state
  }
}

export default TagReducer