import { otpResponse } from "../../domain"
import {
  SURVEY_DEMO,
  CREATE_SURVEY_DEMO
} from "../actions/types"

const initialState = {
  surveydemo: {}
}

const Survey = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_SURVEY_DEMO:
      return { ...state, surveydemo: payload }
    case SURVEY_DEMO:
      return { ...state, surveydemo: payload }
    default:
      return state
  }
}

export default Survey
