import {
  UPDATE_SELECT_ROW_PEOPLE,
  UPDATE_SELECT_ROW,
  UPDATE_OPEN_MODAL_ADD_PEOPLE,
  UPDATE_TAG_DETAIL_DATA,
  UPDATE_TAG_DETAIL_LOADING,
  CLEAR_PEOPLE_TO_TAG
} from "../actions/types"

const initialState = {
  loading: false,
  selectRowsPeople: [],
  selectRows: [],
  openModalAddPeople: false,
  data: [],
  total: 0
}

const TagDetailReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_SELECT_ROW_PEOPLE:
      return {
        ...state,
        selectRowsPeople: payload
      }
    case CLEAR_PEOPLE_TO_TAG:
      return {
        ...state,
        selectRowsPeople: initialState.selectRowsPeople
      }
    case UPDATE_SELECT_ROW:
      return {
        ...state,
        selectRows: payload
      }
    case UPDATE_OPEN_MODAL_ADD_PEOPLE:
      return {
        ...state,
        openModalAddPeople: payload
      }
    case UPDATE_TAG_DETAIL_DATA:
      return {
        ...state,
        data: payload.data,
        total: payload.total
      }
    case UPDATE_TAG_DETAIL_LOADING:
      return {
        ...state,
        loading: payload
      }
    default:
      return state
  }
}

export default TagDetailReducer