import { 
  SUBMIT_MEMBER_OTP_LOADING,
  UPDATE_MEMBER_OTP_FORM,
  UPDATE_MEMBER_OTP_DATA_FORM
} from "../action/types"

const initialState = {
  loading: false,
  requestSaveData: {
    otp: "",
    errorMessage: "",
    status: 0,
    referenceCode: "",
    statusAPIValidate: 0
  },
  otpData: {
    otpId: "",
    otcId: "e91f9199-5d4f-401a-acbb-f5c02a73607f",
    result: null,
    errorCount: true,
    exprCode: true
  }
}

const memberOtpReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBMIT_MEMBER_OTP_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_MEMBER_OTP_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    case UPDATE_MEMBER_OTP_DATA_FORM:
      return {
        ...state,
        otpData: {
          ...state.otpData,
          otpId: payload.otpId,
          otcId: payload.otcId,
          result: payload.result,
          errorCount: payload.errorCount,
          exprCode: payload.exprCode
        }
      }
    default:
      return state
  }
}

export default memberOtpReducer
