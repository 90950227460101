import {
  GET_SURVEY_ID,
  CLEAR_SURVEY_ID,
  GET_ANSWER_SURVEY
} from "../actions/types"

const initialState = {
  surveyId: 0,
  response: {}
}

const editSurvey = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_SURVEY_ID:
      return {
        ...state,
        surveyId: payload
      }
      case CLEAR_SURVEY_ID:
        return {
          ...state,
          surveyId: initialState.surveyId
        }
      case GET_ANSWER_SURVEY:
        return {
          ...state,
          response: payload
        }
    default:
      return state
  }
}

export default editSurvey