import {
  SUBMIT_LOADING_LIST_SURVEY,
  UPDATE_LIST_DATA_LIST_SURVEY,
  UPDATE_PAGINATION_LIST_SURVEY,
  UPDATE_DUPLICATE_STATUS_LIST_SURVEY,
  UPDATE_SURVEY_LIST_REQUEST,
  CLEAR_SURVEY_LIST_REQUEST
} from "../actions/types"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 1,
    perPage: 10
  },
  duplicateSuccess: false,
  requestSurveyList: {
    name: "",
    isActive: null,
    createDate: []
  }
}

const listSurveyReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SUBMIT_LOADING_LIST_SURVEY:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_LIST_DATA_LIST_SURVEY:
      return {
        ...state,
        data: payload.data,
        totalData: payload.totalData
      }
    case UPDATE_PAGINATION_LIST_SURVEY:
      return {
        ...state,
        pagination:{
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_DUPLICATE_STATUS_LIST_SURVEY:
      return {
        ...state,
        duplicateSuccess: payload
      }
    case UPDATE_SURVEY_LIST_REQUEST:
      return {
        ...state,
        requestSurveyList: {
          ...state.requestSurveyList,
          [payload.name]: payload.value
        }
      }
    case CLEAR_SURVEY_LIST_REQUEST :
      return {
        ...state,
        requestSurveyList: initialState.requestSurveyList
      }
    default:
      return state
  }
}

export default listSurveyReducer