import {
  UPDATE_SELECT_ROW_PHONE,
  UPDATE_BACKLIST_DETAIL,
  UPDATE_LOADING_CREATE_BACKLIST,
  UPDATE_COLUMN_FILE_BACKLIST,
  UPDATE_FILE_BACKLIST_IMPORT,
  UPDATE_STEP_IMPORT_FILE_BACKLIST,
  UPDATE_BLACKLIST_PAGINATION,
  UPDATE_IMPORT_DATA
} from "../actions/types"

export const defaultImportData = {
  fileList: [],
  fileloading: false,
  file_size: 0,
  filemax: 1,
  errorMessage: ''
}

const initialState = {
  loading: false,
  loadingCreate: false,
  selectRowsPhone: [],
  backlistDetail: null,
  columnBacklist: [],
  importStep: 1,
  fileBacklistImport: null,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  importData: defaultImportData
}

const BacklistlReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_LOADING_CREATE_BACKLIST:
      return {
        ...state,
        loadingCreate: payload
      }
    case UPDATE_SELECT_ROW_PHONE:
      return {
        ...state,
        selectRowsPhone: payload
      }
    case UPDATE_BACKLIST_DETAIL:
      return {
        ...state,
        backlistDetail: payload
      }
    case UPDATE_COLUMN_FILE_BACKLIST:
      return {
        ...state,
        columnBacklist: payload
      }
    case UPDATE_FILE_BACKLIST_IMPORT:
      return {
        ...state,
        fileBacklistImport: payload
      }
    case UPDATE_STEP_IMPORT_FILE_BACKLIST:
      return {
        ...state,
        importStep: payload
      }
    case UPDATE_BLACKLIST_PAGINATION:
      return {
        ...state,
        pagination: payload
      }
    case UPDATE_IMPORT_DATA:
      return {
        ...state,
        importData: {
          ...state.importData,
          fileList: payload.fileList,
          to: payload.to,
          file_size: payload.file_size
        }
      }
    default:
      return state
  }
}

export default BacklistlReducer