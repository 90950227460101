import {
  CREATE_GAME,
  UPDATE_REQUEST_VALUE,
  UPDATE_IMAGE_DISPLAY_VALUE,
  CREATE_GAME_LOADING,
  CLEAR_REQUEST_SAVE_GAME,
  GET_GAME_DETAIL,
  UPDATE_IMAGE_DETAIL_DISPLAY_VALUE,
  UPDATE_REQUEST_DETAIL_VALUE,
  UPDATE_GAME_CODE,
  UPDATE_SAVED_GAME,
  UPDATE_VALIDATION,
  UPDATE_STATUS_CODE,
  UPDATE_REWARD_SOLD_OUT
} from "../actions/types"

const initialState = {
  requestSaveGameData: {
    gameId: 0,
    gameName: "",
    backgroundImg: "",
    scratchImg: "",
    wheelImg: "",
    wheelCenterImg: "",
    btnImg: "",
    gameType: "",
    percentage: "",
    backgroundColor: "",
    btnBgColor: "",
    wordBtn: "",
    fontColor: "#FFFFFF",
    wink: false,
    isEnd: "",
    flagWink: false,
    backgroundType: 2,
    buttonType: 2,
    flexUsed: false,
    rewardRedeem: "false"
  },
  // requestSaveGameDetailData: {
  //   gameIdDetail: "",
  //   gameNameDetail: "",
  //   backgroundImgDetail: "",
  //   scratchImgDetail: "",
  //   wheelImgDetail: "",
  //   wheelCenterImgDetail: "",
  //   btnImgDetail: "",
  //   gameTypeDetail: "",
  //   percentageDetail: "",
  //   backgroundColorDetail: "",
  //   btnBgColorDetail: "",
  //   wordBtnDetail: "",
  //   fontColorDetail: "",
  //   rewardName: "",
  //   flexUsed: false,
  //   rewardRedeem: "false",
  //   isEnd: "",
  //   flagWink: false
  // },
  imgDisplay: {
    backgroundImgDisplay: "",
    scratchImgDisplay: "",
    wheelImgDisplay: "",
    wheelCenterImgDisplay: "",
    btnImgDisplay: "",
    rewardImgDisplay: ""
  },
  detailImgDisplay: {
    backgroundDetailImgDisplay: "",
    scratchDetailImgDisplay: "",
    wheelDetailImgDisplay: "",
    wheelCenterDetailImgDisplay: "",
    btnDetailImgDisplay: "",
    rewardDetailImgDisplay: ""
  },
  loading: false,
  saved: true,
  validated: true,
  userCode: "",
  gameCode: "",
  statusCode: "",
  rewardId: "",
  rewardName: "",
  isEnd: "",
  flagWink: false,
  rewardSoldOut: false
}

const updateStateFromList = (state, payload) => {

  const updatedData = { ...state.requestSaveGameData }

  for (const key in updatedData) {
    if (payload.hasOwnProperty(key)) {
      updatedData[key] = payload[key]
    }
  }

  return {
    ...state,
    requestSaveGameData: updatedData,
    imgDisplay: {
      ...state.imgDisplay,
      backgroundImgDisplay: payload.backgroundImg,
      backgroundColor: payload.backgroundColor,
      scratchImgDisplay: payload.scratchImg,
      wheelImgDisplay: payload.wheelImg,
      wheelCenterImgDisplay: payload.wheelCenterImg,
      btnImgDisplay: payload.btnImg,
      btnBgColor: payload.btnBgColor,
      rewardImgDisplay: payload.rewardImgId,
      flexUsed: payload.flexUsed,
      rewardRedeem: payload.rewardRedeem
    },
    userCode: payload.userCode,
    backgroundType: payload.backgroundType,
    buttonType: payload.buttonType,
    rewardId: payload.rewardId,
    rewardName: payload.rewardName,
    isEnd: payload.isEnd,
    flagWink: payload.flagWink
  }
}

const createGame = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_REWARD_SOLD_OUT:
      return {
        ...state,
        rewardSoldOut: payload
      }
    case UPDATE_STATUS_CODE:
      return {
        ...state,
        statusCode: payload
      }
    case UPDATE_VALIDATION:
      return {
        ...state,
        validated: payload
      }
    case UPDATE_SAVED_GAME:
      return {
        ...state,
        saved: payload
      }
    case UPDATE_GAME_CODE:
      return {
        ...state,
        gameCode: payload
      }
    case UPDATE_IMAGE_DETAIL_DISPLAY_VALUE:
      return {
        ...state,
        detailImgDisplay: {
          ...state.detailImgDisplay,
          [payload.name]: payload.value
        }
      }
    case GET_GAME_DETAIL:
      return updateStateFromList(state, payload)
    case UPDATE_IMAGE_DISPLAY_VALUE:
      return {
        ...state,
        imgDisplay: {
          ...state.imgDisplay,
          [payload.name]: payload.value
        }
      }
    // case UPDATE_REQUEST_DETAIL_VALUE:
    //   return {
    //     ...state,
    //     requestSaveGameDetailData: {
    //       ...state.requestSaveGameDetailData,
    //       [payload.name]: payload.value
    //     }
    //   }
    case UPDATE_REQUEST_VALUE:
      return {
        ...state,
        requestSaveGameData: {
          ...state.requestSaveGameData,
          [payload.name]: payload.value
        }
      }
    case CREATE_GAME_LOADING: {
      return {
        ...state,
        loading: payload
      }
    }
    case CLEAR_REQUEST_SAVE_GAME:
      return {
        ...state,
        requestSaveGameData: initialState.requestSaveGameData,
        imgDisplay: initialState.imgDisplay,
        // requestSaveGameDetailData: initialState.requestSaveGameDetailData,
        detailImgDisplay: initialState.detailImgDisplay
      }
    default:
      return state
  }
}

export default createGame