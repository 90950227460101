import {
  GET_SURVEY_DATA,
  GET_TRANSACTION_ID,
  UPDATE_JSON_ANSWER,
  UPDATE_SURVEY_ANSWER_DATA
} from "../actions/types"

const initialState = {
  page: [],
  surveyCode: "",
  timeLimitOnePage: 0,
  timeLimitSurvey: 0,
  transactionId: "",
  jsonAnswer : [],
  listDataAnswer: [],
  backgroundColor: "",
  logoImg: "",
  backgroundImg: "",
  urlLink: ""
}

const formSurvey = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_SURVEY_DATA:
      return {
        ...state,
        page: payload.question,
        surveyCode: payload.surveyCode,
        timeLimitOnePage: payload.timeLimitOnePage,
        timeLimitSurvey: payload.timeLimitSurvey,
        backgroundColor: payload.backgroundColor,
        logoImg: payload.logoImg,
        backgroundImg: payload.backgroundImg,
        urlLink: payload.urlLink
      }
    case GET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: payload.transactionId
      }
    case UPDATE_JSON_ANSWER:
      return {
        ...state,
        jsonAnswer: payload.jsonAnswer
      }
    case UPDATE_SURVEY_ANSWER_DATA:
      return {
        ...state,
        listDataAnswer: payload
      }
    default:
      return state
  }
}

export default formSurvey