export const CREATE_GAME = "CREATE_GAME"
export const CREATE_GAME_LOADING  = "CREATE_GAME_LOADING"
export const UPDATE_REQUEST_VALUE = "UPDATE_REQUEST_VALUE"
export const UPDATE_IMAGE_DISPLAY_VALUE = "UPDATE_IMAGE_DISPLAY_VALUE"
export const CLEAR_REQUEST_SAVE_GAME = "CLEAR_REQUEST_SAVE_GAME"
export const GET_GAME_DETAIL = "GET_GAME_DETAIL"
export const UPDATE_IMAGE_DETAIL_DISPLAY_VALUE = "UPDATE_IMAGE_DETAIL_DISPLAY_VALUE"
export const UPDATE_REQUEST_DETAIL_VALUE = "UPDATE_REQUEST_DETAIL_VALUE"
export const UPDATE_GAME_CODE = "UPDATE_GAME_CODE"
export const UPDATE_SAVED_GAME = "UPDATE_SAVED_GAME"
export const UPDATE_VALIDATION = "UPDATE_VALIDATION"
export const UPDATE_STATUS_CODE = "UPDATE_STATUS_CODE"
export const UPDATE_REWARD_SOLD_OUT = "UPDATE_REWARD_SOLD_OUT"