import {
  SUBMIT_MEMBER_USER_POINT_SUCCESS,
  UPDATE_MEMBER_USER_POINT_LIST_LOADING,
  UPDATE_MEMBER_USER_POINT_LIST_PAGINATION,
  SUBMIT_MEMBER_USER_REWARDS_SUCCESS,
  UPDATE_MEMBER_USER_REWARDS_LIST_LOADING,
  UPDATE_MEMBER_USER_REWARDS_LIST_PAGINATION,
  SUBMIT_MEMBER_USER_USED_SUCCESS,
  UPDATE_MEMBER_USER_USED_LIST_LOADING,
  UPDATE_MEMBER_USER_USED_LIST_PAGINATION,
  SUBMIT_MEMBER_USER_EXPIRE_SUCCESS,
  UPDATE_MEMBER_USER_EXPIRE_LIST_LOADING,
  UPDATE_MEMBER_USER_EXPIRE_LIST_PAGINATION,
  UPDATE_MEMBER_USER_FILTER
} from "../actions/types"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  loadingRewards: false,
  dataRewards: [],
  totalDataRewards: 0,
  paginationRewards: {
    currentPage: 0,
    perPage: 10
  },
  loadingUsed: false,
  dataUsed: [],
  totalDataUsed: 0,
  paginationUsed: {
    currentPage: 0,
    perPage: 10
  },
  loadingExpire: false,
  dataExpire: [],
  totalDataExpire: 0,
  paginationExpire: {
    currentPage: 0,
    perPage: 10
  },
  filter: ""
}

const memberUser = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_MEMBER_USER_POINT_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_USER_POINT_SUCCESS:
      return {
        ...state,
        data: payload.data,
        totalData : payload.totalData
      }
    case UPDATE_MEMBER_USER_POINT_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_MEMBER_USER_REWARDS_LIST_PAGINATION:
      return {
        ...state,
        paginationRewards: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_USER_REWARDS_SUCCESS:
      return {
        ...state,
        dataRewards: payload.data,
        totalDataRewards: payload.totalData
      }
    case UPDATE_MEMBER_USER_REWARDS_LIST_LOADING:
      return {
        ...state,
        loadingRewards: payload
      }
    case UPDATE_MEMBER_USER_USED_LIST_PAGINATION:
      return {
        ...state,
        paginationUsed: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_USER_USED_SUCCESS:
      return {
        ...state,
        dataUsed: payload.data,
        totalDataUsed: payload.totalData
      }
    case UPDATE_MEMBER_USER_USED_LIST_LOADING:
      return {
        ...state,
        loadingUsed: payload
      }
    case UPDATE_MEMBER_USER_EXPIRE_LIST_PAGINATION:
      return {
        ...state,
        paginationExpire: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_USER_EXPIRE_SUCCESS:
      return {
        ...state,
        dataExpire: payload.data,
        totalDataExpire: payload.totalData
      }
    case UPDATE_MEMBER_USER_EXPIRE_LIST_LOADING:
      return {
        ...state,
        loadingExpire: payload
      }
    case UPDATE_MEMBER_USER_FILTER:
      return {
        ...state,
        filter: payload
      }
    default:
      return state
  }
}

export default memberUser