import { otpResponse } from "../../domain"
import {
  COUPON_DEMO,
  CREATE_COUPON_DEMO
} from "../actions/types"

const initialState = {
  coupondemo: {}
}

const Coupon = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_COUPON_DEMO:
      return { ...state, coupondemo: payload }
    case COUPON_DEMO:
      return { ...state, coupondemo: payload }
    default:
      return state
  }
}

export default Coupon
