import {
  SUBMIT_MEMBER_PREVIEW_SUCCESS,
  UPDATE_MEMBER_PREVIEW_LOADING,
  RESET_MEMBER_VIEW_DATA,
  UPDATE_MEMBER_INPUT_REQUIRE_ERROR,
  UPDATE_ANSWER_DATA,
  SWITCH_MEMBER_TOGGLE_EDIT,
  UPDATE_SHARE_STATUS
} from "../actions/types"

const initialState = {
  coupondemo: {},
  answerData: [],
  loading: false,
  isEdit: false,
  surveyCode: "",
  surveyViewData: "",
  surveyFromData: {},
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  error: false,
  loadingForm: false,
  requestSaveData: {
    campName: "",
    action: false,
    detail: "",
    createUpdate: "",
    lastUpdate: "",
    total: "",
    redeem: "",
    expired: ""
  },
  shareStatus: null
}

const memberView = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SUBMIT_MEMBER_PREVIEW_SUCCESS:
      return {
        ...state,
        isEdit: payload.isEdit,
        surveyCode: payload.surveyCode,
        surveyViewData: payload.data
      }
    case UPDATE_MEMBER_PREVIEW_LOADING:
      return {
        ...state,
        loading: payload
      }
    case RESET_MEMBER_VIEW_DATA:
      return {
        ...state,
        isEdit: false,
        surveyViewData: ""
      }
    case UPDATE_MEMBER_INPUT_REQUIRE_ERROR:
      return {
        ...state,
        error: payload
      }
    case UPDATE_SHARE_STATUS:
      return {
        ...state,
        shareStatus: payload
      }
    case UPDATE_ANSWER_DATA:
      return {
        ...state,
        answerData: payload,
        error: false
      }
    case SWITCH_MEMBER_TOGGLE_EDIT:
      return {
        ...state,
        isEdit: payload.isEdit
      }
    default:
      return state
  }
}

export default memberView