import { 
  COUPON_BOOK_DATA_REQUEST,
  COUPON_BOOK_DETAIL_DATA_REQUEST,
  UPDATE_PAGINATION_COUPON_BOOK_LIST,
  UPDATE_COUPON_BOOK_LOADING,
  UPDATE_DETAIL_PAGINATION_COUPON_BOOK_LIST
} from "../actions/types"

const initialState = {
  couponBookList: [],
  couponBookDetailList: [],
  totalData: 0,
  pagination: {
    currentPage: 1,
    perPage: 10
  },
  loading: false,
  totalDetailData: 0,
  detailPagination: {
    currentPage: 1,
    perPage: 10
  }
}

const couponsBook = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_COUPON_BOOK_LOADING:
      return {
        ...state, loading: payload
      }
    case UPDATE_PAGINATION_COUPON_BOOK_LIST:
      return {
        ...state,
        pagination:{
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
      case UPDATE_DETAIL_PAGINATION_COUPON_BOOK_LIST:
        return {
          ...state,
          detailPagination:{
            currentPage: payload.currentPage,
            perPage: payload.perPage
          }
        }
    case COUPON_BOOK_DATA_REQUEST:
      return {
        ...state,
        couponBookList: payload.data,
        totalData: payload.totalData
      }
    case COUPON_BOOK_DETAIL_DATA_REQUEST:
      return {
        ...state,
        couponBookDetailList: payload.data,
        totalDetailData: payload.totalData
      }
    default:
      return state
  }
}

export default couponsBook