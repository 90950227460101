import {
  SUBMIT_SURVEY_PREVIEW_SUCCESS,
  UPDATE_SURVEY_PREVIEW_LOADING,
  RESET_SURVEY_VIEW_DATA,
  UPDATE_SURVEY_INPUT_REQUIRE_ERROR,
  UPDATE_ANSWER_DATA,
  SWITCH_TOGGLE_EDIT
} from "../actions/types"

const initialState = {
  coupondemo: {},
  answerData: [],
  loading: false,
  isEdit: false,
  surveyCode: "",
  surveyViewData: "",
  surveyFromData: {},
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  error: false,
  loadingForm: false,
  requestSaveData: {
    campName: "",
    action: false,
    detail: "",
    createUpdate: "",
    lastUpdate: "",
    total: "",
    redeem: "",
    expired: ""
  }
}

const surveyView = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SUBMIT_SURVEY_PREVIEW_SUCCESS:
      return {
        ...state,
        isEdit: payload.isEdit,
        surveyCode: payload.surveyCode,
        surveyViewData: payload.data
      }
    case UPDATE_SURVEY_PREVIEW_LOADING:
      return {
        ...state,
        loading: payload
      }
    case RESET_SURVEY_VIEW_DATA:
      return {
        ...state,
        isEdit: false,
        surveyViewData: ""
      }
    case UPDATE_SURVEY_INPUT_REQUIRE_ERROR:
      return {
        ...state,
        error: payload
      }
    case UPDATE_ANSWER_DATA:
      return {
        ...state,
        answerData: payload,
        error: false
      }
    case SWITCH_TOGGLE_EDIT:
      return {
        ...state,
        isEdit: payload.isEdit
      }
    default:
      return state
  }
}

export default surveyView