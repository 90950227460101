export const surveyDataList = {
    data: [
        {
            id: 1,
            title: 'Survey Name 1',
            amountCreater: '5',
            status: true
        },
        {
            id: 2,
            title: 'Survey Name 2',
            amountCreater: '1',
            status: true
        },
        {
            id: 3,
            title: 'Survey Name 3',
            amountCreater: '3',
            status: true
        },
        {
            id: 4,
            title: 'Survey Name 4',
            amountCreater: '1',
            status: false
        },
        {
            id: 5,
            title: 'Survey Name 5',
            amountCreater: '5',
            status: false
        },
        {
            id: 6,
            title: 'Survey Name 6',
            amountCreater: '1',
            status: true
        },
        {
            id: 7,
            title: 'Survey Name 7',
            amountCreater: '5',
            status: false
        },
        {
            id: 8,
            title: 'Survey Name 8',
            amountCreater: '6',
            status: false
        },
        {
            id: 9,
            title: 'Survey Name 9',
            amountCreater: '5',
            status: true
        }
    ],
    limit: 10,
    offset: 1,
    total: 6
}

export const surveyDataById = {
    title: "",
    description: "",
    language: "english",
    orderNumber: false,
    state: "c",
    styles: [
        {
            NAME: "border-radius",
            VALUE: "0px !important"
        },
        {
            NAME: "padding-top",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-bottom",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-left",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-right",
            VALUE: "5px !important"
        },
        {
            NAME: "background-size",
            VALUE: "auto auto"
        },
        {
            NAME: "background-repeat",
            VALUE: "repeat"
        },
        {
            NAME: "background-color",
            VALUE: "white !important"
        }
    ],
    pages: [
        {
            rows: [
                {
                    styles: [
                        {
                            NAME: "height",
                            VALUE: "100%"
                        }
                    ],
                    cols: [
                        {
                            showstate: "a",
                            content: {
                                default: true,
                                type: "img",
                                require: false,
                                properties: { padding: '1px' },
                                stamp: 'show',
                                stampLeft: '41',
                                stampTop: '19',
                                stampWidth: '200',
                                values: {
                                    value: null, file: null
                                },
                                choices: [
                                    {
                                        value: 0, items: "item1"
                                    }
                                ]

                            },
                            styles: [
                                {
                                    NAME: "padding-top",
                                    VALUE: "0px !important"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            rows: [
                {
                    styles: [
                        {
                            NAME: "height",
                            VALUE: "100%"
                        }
                    ],
                    cols: [
                        {
                            showstate: "a",
                            content: {
                                type: "singleInput",
                                require: false,
                                inputType: "",
                                title: "Firstname",
                                placeholder: "Firstname",
                                errorMSG: "invalid",
                                values: {
                                    value: null, file: null
                                },
                                choices: [
                                    {
                                        value: 0, items: "item1"
                                    }
                                ]

                            },
                            styles: [
                                {
                                    NAME: "padding-top",
                                    VALUE: "0px !important"
                                }
                            ]
                        }
                    ]
                },
                {
                    styles: [
                        {
                            NAME: "height",
                            VALUE: "100%"
                        }
                    ],
                    cols: [
                        {
                            showstate: "a",
                            content: {
                                type: "singleInput",
                                require: false,
                                inputType: "",
                                title: "Lastname",
                                placeholder: "Lastname",
                                errorMSG: "invalid",
                                values: {
                                    value: null, file: null
                                },
                                choices: [
                                    {
                                        value: 0, items: "item1"
                                    }
                                ]

                            },
                            styles: [
                                {
                                    NAME: "padding-top",
                                    VALUE: "0px !important"
                                }
                            ]
                        }
                    ]
                },
                {
                    styles: [
                        {
                            NAME: "height",
                            VALUE: "100%"
                        }
                    ],
                    cols: [
                        {
                            showstate: "a",
                            content: {
                                type: "singleInput",
                                require: true,
                                inputType: "",
                                title: "Phone Number",
                                placeholder: "Phone Number",
                                errorMSG: "invalid Phone",
                                values: {
                                    value: null, file: null
                                },
                                choices: [
                                    {
                                        value: 0, items: "item1"
                                    }
                                ]

                            },
                            styles: [
                                {
                                    NAME: "padding-top",
                                    VALUE: "0px !important"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}

export const surveyMockForRama = {
    title: "แบบสอบถามความพึ่งพอใจ",
    description: "",
    state: "c",
    styles: [
        {
            NAME: "border-radius",
            VALUE: "0px !important"
        },
        {
            NAME: "padding-top",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-bottom",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-left",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-right",
            VALUE: "5px !important"
        },
        {
            NAME: "background-size",
            VALUE: "auto auto"
        },
        {
            NAME: "background-repeat",
            VALUE: "repeat"
        },
        {
            NAME: "background-color",
            VALUE: "white !important"
        }
    ],
    pages: [
        {
            rows: [
                {
                    styles: [
                        {
                            NAME: "height",
                            VALUE: "100%"
                        }
                    ],
                    cols: [
                        {
                            showstate: "c",
                            content: {
                                type: "singleInput",
                                require: false,
                                inputType: "",
                                title: "ระบบการบริการด้านรักษาพยาบาล",
                                placeholder: "Answer",
                                choices: [{ value: 0, items: "item1" }]
                            },
                            styles: [
                                {
                                    NAME: "padding-top",
                                    VALUE: "0px !important"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            rows: [
                {
                    styles: [
                        {
                            NAME: "height",
                            VALUE: "100%"
                        }
                    ],
                    cols: [
                        {
                            showstate: "c",
                            content: {
                                type: "singleInput",
                                inputType: "",
                                title: "อาคารสถานที่",
                                placeholder: "Answer",
                                list: [
                                    {
                                        value: "1",
                                        text: "Value 1"
                                    }
                                ]
                            },
                            styles: [
                                {
                                    NAME: "padding-top",
                                    VALUE: "0px !important"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}

export const defaultTemplate = [
    {
        pageTitle: "",
        pageDescription: "",
        pageRoute: "",
        peopleId: false,
        question: [
            {
                questionId: "ec839693-65f1-4bc7-80bf-afd268f25451",
                name: "Phone number",
                type: "singleInput",
                isRequire: true,
                isValidation: false,
                validationId: null,
                questionNumber: 1,
                page: 1,
                choiceType: "number",
                choiceAmountRating: null,
                choices: [
                    {
                        choiceId: 1,
                        name: "",
                        choiceValidation: null,
                        pageValidation: null
                    }
                ],
                chosen: false,
                selected: false
            },
            {
                questionId: "6bd408f7-db6a-4a05-b7f9-1cab5e6b060b",
                name: "First name",
                type: "singleInput",
                isRequire: false,
                isValidation: false,
                validationId: null,
                questionNumber: 2,
                page: 1,
                choiceType: "text",
                choiceAmountRating: null,
                choices: [
                    {
                        choiceId: 1,
                        name: "",
                        choiceValidation: null,
                        pageValidation: null
                    }
                ],
                chosen: false,
                selected: false
            },
            {
                questionId: "8869eddb-1878-4e31-9c59-e30997c96976",
                name: "Last name",
                type: "singleInput",
                isRequire: false,
                isValidation: false,
                validationId: null,
                questionNumber: 3,
                page: 1,
                choiceType: "text",
                choiceAmountRating: null,
                choices: [
                    {
                        choiceId: 1,
                        name: "",
                        choiceValidation: null,
                        pageValidation: null
                    }
                ],
                chosen: false,
                selected: false
            }
        ]
    }
]

export const memberUserPointListData = [
    {
      id: '1',
      usageList: 'test1',
      type: 'type1',
      point: "+75",
      dateOfPurchase: new Date()
    },
    {
      id: '2',
      usageList: 'test2',
      type: 'type2',
      point: "+129",
      dateOfPurchase: new Date()
    },
    {
      id: '3',
      usageList: 'test3',
      type: 'type3',
      point: "-84",
      dateOfPurchase: new Date()
    },
    {
      id: '4',
      usageList: 'test4',
      type: 'type4',
      point: "-3",
      dateOfPurchase: new Date()
    },
    {
      id: '5',
      usageList: 'test5',
      type: 'type5',
      point: "+73",
      dateOfPurchase: new Date()
    },
    {
      id: '6',
      usageList: 'test6',
      type: 'type6',
      point: "-11",
      dateOfPurchase: new Date()
    }
]

export const memberUserRewardsListData = [
    {
      id: '1',
      header: 'header1',
      subheader: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      point: "1175",
      endDate: new Date(),
      img: "2c2f400d-9a72-4c6d-9561-61c4954f3b42"
    },
    {
      id: '2',
      header: 'header2',
      subheader: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      point: "1129",
      endDate: new Date(),
      img: "2c2f400d-9a72-4c6d-9561-61c4954f3b42"
    },
    {
      id: '3',
      header: 'header3',
      subheader: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      point: "1184",
      endDate: new Date(),
      img: "2c2f400d-9a72-4c6d-9561-61c4954f3b42"
    },
    {
      id: '4',
      header: 'header4',
      subheader: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      point: "130",
      endDate: new Date(),
      img: "2c2f400d-9a72-4c6d-9561-61c4954f3b42"
    },
    {
      id: '5',
      header: 'header5',
      subheader: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      point: "730",
      endDate: new Date(),
      img: "2c2f400d-9a72-4c6d-9561-61c4954f3b42"
    },
    {
      id: '6',
      header: 'header6',
      subheader: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      point: "110",
      endDate: new Date(),
      img: "2c2f400d-9a72-4c6d-9561-61c4954f3b42"
    }
]

export const memberDashboardListData = [
    {
      id: '1',
      name: 'template1',
      used: '4',
      createDate: new Date()
    },
    {
      id: '2',
      name: 'template2',
      used: '10',
      createDate: new Date()
    },
    {
      id: '3',
      name: 'template3',
      used: '7',
      createDate: new Date()
    },
    {
      id: '4',
      name: 'template4',
      used: '58',
      createDate: new Date()
    }
]

export const templateDataChart = {
    title: "Top 10 by Client Used",
    categories: [
        "Template1",
        "Template2",
        "Template3",
        "Template4",
        "Template5",
        "Template6",
        "Template7",
        "Template8",
        "Template9",
        "Template10"
    ],
    value: [
        2420775,
        2276400,
        1303440,
        1164320,
        1152900,
        787200,
        775500,
        752000,
        747600,
        709800
    ]
}