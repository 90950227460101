import { COUPON_DETAIL, COUPON_LOADING } from "../actions/types"

const initialState = {
  couponDetail: "",
  loading: false
}

const couponLandingPage = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case COUPON_LOADING :
      return {
        ...state,
        loading: payload
      }
    case COUPON_DETAIL:
      return {
        ...state,
        couponDetail: payload
      }
    default:
      return state
  }
}

export default couponLandingPage