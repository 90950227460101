import { 
  SUBMIT_BOARDCAST_LOADING,
  UPDATE_BOARDCAST,
  CLEAR_BOARDCAST,
  UPDATE_IMPORT_DATA,
  UPDATE_HEADER,
  UPDATE_BODY
} from "../actions/types"
import { ParameterContent_defaule } from "../../../domain"

export const defaultImportData = {
  fileList: [],
  fileloading: false,
  file_size: 0,
  filemax: 1,
  errorMessage: ''
}

const initialState = {
  loading: false,
  requestSaveData: {
    mobile: "",
    description: ""
  },
  importData: defaultImportData,
  header: null,
  body: null
}


const boardcastReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_BOARDCAST:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    case SUBMIT_BOARDCAST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case CLEAR_BOARDCAST: 
      return initialState
    case UPDATE_IMPORT_DATA:
      return {
        ...state,
        importData: {
          ...state.importData,
          fileList: payload.fileList,
          to: payload.to,
          file_size: payload.file_size
        }
      }
    case UPDATE_HEADER:
      return {
        ...state,
        header: payload
      }
    case UPDATE_BODY:
      return {
        ...state,
        body: payload
      }
    default:
      return state
  }
}

export default boardcastReducer