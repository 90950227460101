import {
  GET_MEMBER_FORM_DATA,
  GET_TRANSACTION_ID_MEMBER_FORM,
  UPDATE_JSON_ANSWER_MEMBER_FORM,
  UPDATE_MEMBER_FORM_ANSWER_DATA,
  UPDATE_MEMBER_FORM_CODE,
  UPDATE_MEMBER_PEOPLE_ID
} from "../actions/types"

const initialState = {
  page: [],
  surveyCode: "",
  timeLimitOnePage: 0,
  code: 0,
  timeLimitSurvey: 0,
  transactionId: "",
  jsonAnswer : [],
  listDataAnswer: [],
  backgroundColor: "",
  logoImg: "",
  backgroundImg: "",
  urlLink: "",
  status: 1,
  peopleId: ""
}

const memberForm = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_MEMBER_FORM_DATA:
      return {
        ...state,
        page: payload.question,
        surveyCode: payload.surveyCode,
        timeLimitOnePage: payload.timeLimitOnePage,
        timeLimitSurvey: payload.timeLimitSurvey,
        backgroundColor: payload.backgroundColor,
        logoImg: payload.logoImg,
        backgroundImg: payload.backgroundImg,
        urlLink: payload.urlLink,
        status: payload.status
      }
    case GET_TRANSACTION_ID_MEMBER_FORM:
      return {
        ...state,
        transactionId: payload.transactionId
      }
    case UPDATE_JSON_ANSWER_MEMBER_FORM:
      return {
        ...state,
        jsonAnswer: payload.jsonAnswer
      }
    case UPDATE_MEMBER_FORM_ANSWER_DATA:
      return {
        ...state,
        listDataAnswer: payload
      }
    case UPDATE_MEMBER_FORM_CODE:
      return {
        ...state,
        code: payload
      }
    case UPDATE_MEMBER_PEOPLE_ID:
      return {
        ...state,
        peopleId: payload
      }
    default:
      return state
  }
}

export default memberForm