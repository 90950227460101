import {
  CREATE_SURVEY_DEMO,
  SUBMIT_SURVEY_SUCCESS,
  UPDATE_SURVEY_LIST_LOADING,
  UPDATE_SURVEY_LIST_PAGINATION,
  UPDATE_SURVEY_FORM,
  UPDATE_SURVEY_FORM_BY_ID,
  SUBMIT_SURVEY_FORM_LOADING,
  SUBMIT_SURVEY_FORM_REQUEST_SUCCESS,
  RESET_SURVEY_CREATE_ID,
  UPDATE_SURVEY_VIEW_ID,
  RESET_SURVEY_VIEW_ID,
  UPDATE_SURVEY_ROWS_CONTENT
} from "../actions/types"

const initialState = {
  surveyViewId: null,
  coupondemo: {},
  loading: false,
  data: [],
  surveyFromData: {},
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  loadingForm: false,
  requestSaveData: {
    campName: "",
    action: false,
    detail: "",
    createUpdate: "",
    lastUpdate: "",
    total: "",
    redeem: "",
    expired: ""
  },
  totalRows: 0  
}

const surveyPage = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_SURVEY_DEMO:
      return { ...state, coupondemo: payload }
    case UPDATE_SURVEY_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    case UPDATE_SURVEY_FORM_BY_ID:
      return {
        ...state,
        surveyFromData: payload.response
      }
    case SUBMIT_SURVEY_FORM_REQUEST_SUCCESS:
      return initialState
    case SUBMIT_SURVEY_FORM_LOADING:
      return {
        ...state,
        loadingForm: payload
      }
    case SUBMIT_SURVEY_SUCCESS:
      return {
        ...state,
        data: payload.data,
        surveyViewId: null,
        totalData: payload.totalData
      }
    case UPDATE_SURVEY_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case RESET_SURVEY_CREATE_ID:
      return {
        ...state,
        surveyViewId : payload
      }
    case UPDATE_SURVEY_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_SURVEY_VIEW_ID:
      return {
        ...state,
        surveyViewId: payload.id
      }
    case RESET_SURVEY_VIEW_ID:
      return {
        ...state,
        surveyViewId: initialState.surveyViewId
      }
    case UPDATE_SURVEY_ROWS_CONTENT:
      return {
        ...state,
        totalRows: state.totalRows + 1
      }
    default:
      return state
  }
}

export default surveyPage