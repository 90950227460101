import {
  SUBMIT_MEMBER_SUCCESS,
  UPDATE_MEMBER_LIST_LOADING,
  UPDATE_MEMBER_LIST_PAGINATION,
  UPDATE_TEMP_LIST_PAGINATION,
  UPDATE_MEMBER_LIST_FILTER,
  UPDATE_TEMPLATE_ROUTE
} from "../actions/types"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  paginationTemp: {
    currentPage: 0,
    perPage: 10
  },
  filter: "",
  templateRoute: {
    id: "",
    name: "",
    createDate: ""
  }
}

const memberList = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_MEMBER_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_TEMP_LIST_PAGINATION:
      return {
        ...state,
        paginationTemp: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_SUCCESS:
      return {
        ...state,
        data: payload.data,
        totalData : payload.totalData
      }
    case UPDATE_MEMBER_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_MEMBER_LIST_FILTER:
      return {
        ...state,
        filter: payload
      }
    case UPDATE_TEMPLATE_ROUTE :
      return {
        ...state,
        templateRoute: {
          id: payload.id,
          name: payload.name,
          createDate: payload.createDate
        }
      }
    default:
      return state
  }
}

export default memberList