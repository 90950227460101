export const CREATE_SURVEY_DEMO = "CREATE_SURVEY_DEMO"
export const SUBMIT_SURVEY_SUCCESS = "SUBMIT_SURVEY_SUCCESS"
export const UPDATE_SURVEY_LIST_LOADING = "UPDATE_SURVEY_LIST_LOADING"
export const UPDATE_SURVEY_LIST_PAGINATION = "UPDATE_SURVEY_LIST_PAGINATION"
export const UPDATE_SURVEY_FORM = "UPDATE_SURVEY_FORM"
export const UPDATE_SURVEY_FORM_BY_ID = "UPDATE_SURVEY_FORM_BY_ID"
export const SUBMIT_SURVEY_FORM_LOADING = "SUBMIT_SURVEY_FORM_LOADING"
export const SUBMIT_SURVEY_FORM_REQUEST_SUCCESS = "SUBMIT_SURVEY_FORM_REQUEST_SUCCESS"
export const RESET_SURVEY_CREATE_ID = "RESET_SURVEY_CREATE_ID"
export const UPDATE_SURVEY_VIEW_ID = "UPDATE_SURVEY_VIEW_ID"
export const RESET_SURVEY_VIEW_ID = "RESET_SURVEY_VIEW_ID"
export const UPDATE_SURVEY_ROWS_CONTENT = "UPDATE_SURVEY_ROWS_CONTENT"