import { 
  UPDATE_MEMBER_DASHBOARD_MAIN_LOADING,
  GET_MEMBER_DASHBOARD_MAIN_DETAIL,
  SUBMIT_MEMBER_DASHBOARD_SUCCESS,
  UPDATE_MEMBER_DASHBOARD_LOADING,
  UPDATE_MEMBER_DASHBOARD_PAGINATION,
  UPDATE_MEMBER_DASHBOARD_TEMPLATE_LOADING,
  UPDATE_MEMBER_DASHBOARD_TEMPLATE_LIST_DATA,
  UPDATE_UNIQUE_KEY,
  UPDATE_KEY_DEFAULT_PARA
} from "../actions/types"

const initialState = {
  loadingMemberDashboard: false,
  memberDashboardMainData: {
    total: 0,
    totalUser: 0,
    usedChart: [],
    active: 0,
    inactive: 0,
    activePercent: "",
    inactivePercent: "",
    yearChart: []
  },
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  loadingTemplate: false,
  dataTemplate: [],
  uniqueKey: null,
  keyDefaultPara: null
}

const memberDashboard = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_MEMBER_DASHBOARD_MAIN_DETAIL :
      return {
        ...state,
        memberDashboardMainData: {
          total: payload.total,
          totalUser: payload.totalUser,
          usedChart: payload.usedChart,
          active: payload.active,
          inactive: payload.inactive,
          activePercent: payload.activePercent,
          inactivePercent: payload.inactivePercent,
          yearChart: payload.yearChart
          
        }
      }
    case UPDATE_MEMBER_DASHBOARD_MAIN_LOADING :
      return {
        ...state,
        loadingMemberDashboard: payload
      }
    case UPDATE_MEMBER_DASHBOARD_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case SUBMIT_MEMBER_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: payload.data,
        totalData : payload.totalData
      }
    case UPDATE_MEMBER_DASHBOARD_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_MEMBER_DASHBOARD_TEMPLATE_LOADING:
      return {
        ...state,
        loadingTemplate: payload
      }
    case UPDATE_MEMBER_DASHBOARD_TEMPLATE_LIST_DATA:
      return {
        ...state,
        dataTemplate: payload.data
      }
    case UPDATE_UNIQUE_KEY:
      return {
        ...state,
        uniqueKey: payload
      }
    case UPDATE_KEY_DEFAULT_PARA:
      return {
        ...state,
        keyDefaultPara: payload
      }
    default:
      return state
  }
}

export default memberDashboard