export const surveyDataList = {
  data: [
    {
      id: 1,
      title: 'Survey Name 1',
      amountCreater: '5',
      status: true
    },
    {
      id: 2,
      title: 'Survey Name 2',
      amountCreater: '1',
      status: true
    },
    {
      id: 3,
      title: 'Survey Name 3',
      amountCreater: '3',
      status: true
    },
    {
      id: 4,
      title: 'Survey Name 4',
      amountCreater: '1',
      status: false
    },
    {
      id: 5,
      title: 'Survey Name 5',
      amountCreater: '5',
      status: false
    },
    {
      id: 6,
      title: 'Survey Name 6',
      amountCreater: '1',
      status: true
    },
    {
      id: 7,
      title: 'Survey Name 7',
      amountCreater: '5',
      status: false
    },
    {
      id: 8,
      title: 'Survey Name 8',
      amountCreater: '6',
      status: false
    },
    {
      id: 9,
      title: 'Survey Name 9',
      amountCreater: '5',
      status: true
    }
  ],
  limit: 10,
  offset: 1,
  total: 6
}

export const surveyDataById = {
    title: "",
    description: "",
    language: "english",
    orderNumber: false,
    state: "c",
    styles: [
        {
            NAME: "border-radius",
            VALUE: "0px !important"
        },
        {
            NAME: "padding-top",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-bottom",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-left",
            VALUE: "5px !important"
        },
        {
            NAME: "padding-right",
            VALUE: "5px !important"
        },
        {
            NAME: "background-size",
            VALUE: "auto auto"
        },
        {
            NAME: "background-repeat",
            VALUE: "repeat"
        },
        {
            NAME: "background-color",
            VALUE: "white !important"
        }
    ],
    pages: [
      {
        rows: [
          {
              styles: [
                  {
                      NAME: "height",
                      VALUE: "100%"
                  }
              ],
              cols: [
                  {
                      showstate: "c",
                      content: {
                          type: "singleInput",
                          require: false,
                          inputType: "",
                          title: "",
                          placeholder: "Answer",
                          errorMSG: "invalid phone",
                          values: {
                              value: null
                          },
                          choices: [
                            {
                                value: 0, items: "item1"
                            }
                        ]

                      },
                      styles: [
                          {
                              NAME: "padding-top",
                              VALUE: "0px !important"
                          }
                      ]
                  }
              ]
          }
        ]
      }
    ]
}

export const surveyMockForRama = {
  title: "แบบสอบถามความพึ่งพอใจ",
  description: "",
  state: "c",
  styles: [
      {
          NAME: "border-radius",
          VALUE: "0px !important"
      },
      {
          NAME: "padding-top",
          VALUE: "5px !important"
      },
      {
          NAME: "padding-bottom",
          VALUE: "5px !important"
      },
      {
          NAME: "padding-left",
          VALUE: "5px !important"
      },
      {
          NAME: "padding-right",
          VALUE: "5px !important"
      },
      {
          NAME: "background-size",
          VALUE: "auto auto"
      },
      {
          NAME: "background-repeat",
          VALUE: "repeat"
      },
      {
          NAME: "background-color",
          VALUE: "white !important"
      }
  ],
  pages: [
    {
      rows: [
        {
            styles: [
                {
                    NAME: "height",
                    VALUE: "100%"
                }
            ],
            cols: [
                {
                    showstate: "c",
                    content: {
                        type: "singleInput",
                        require: false,
                        inputType: "",
                        title: "ระบบการบริการด้านรักษาพยาบาล",
                        placeholder: "Answer",
                        choices: [{value: 0, items: "item1"}]
                    },
                    styles: [
                        {
                            NAME: "padding-top",
                            VALUE: "0px !important"
                        }
                    ]
                }
            ]
        }
      ]
    },
    {
      rows: [
        {
            styles: [
                {
                    NAME: "height",
                    VALUE: "100%"
                }
            ],
            cols: [
                {
                    showstate: "c",
                    content: {
                        type: "singleInput",
                        inputType: "",
                        title: "อาคารสถานที่",
                        placeholder: "Answer",
                        list: [
                            {
                                value: "1",
                                text: "Value 1"
                            }
                        ]
                    },
                    styles: [
                        {
                            NAME: "padding-top",
                            VALUE: "0px !important"
                        }
                    ]
                }
            ]
        }
      ]
    }
  ]
}